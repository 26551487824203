import type {
  Configuration,
  CreateSamlIdentityProviderConfigInput,
  UpdateSamlIdentityProviderInput,
} from '@repo/api-gw-sdk';
import {
  ListSamlIdentityProviderConfigsApi,
  CreateSamlIdentityProviderConfigApi,
  DeleteSamlIdentityProviderConfigApi,
  UpdateSamlIdentityProviderConfigOverrideApi,
} from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

const baseUrl = '/samlIdentityProvider';

export const saml = (httpClient: HttpClient, config: Configuration) => {
  const listSamlIdentityProviderConfigsApi =
    new ListSamlIdentityProviderConfigsApi(config);
  const createSamlIdentityProviderConfigApi =
    new CreateSamlIdentityProviderConfigApi(config);
  const updateSamlIdentityProviderConfigOverrideApi =
    new UpdateSamlIdentityProviderConfigOverrideApi(config);
  const deleteSamlIdentityProviderConfigApi =
    new DeleteSamlIdentityProviderConfigApi(config);

  return {
    get: () =>
      httpClient.execute([baseUrl], () =>
        listSamlIdentityProviderConfigsApi.listSamlIdentityProviderConfigs()
      ),
    create: (saml: CreateSamlIdentityProviderConfigInput) =>
      createSamlIdentityProviderConfigApi.createSamlIdentityProviderConfig(
        saml
      ),
    update: (id: string, saml: UpdateSamlIdentityProviderInput) =>
      updateSamlIdentityProviderConfigOverrideApi.updateSamlConfigOverride(
        id,
        saml
      ),
    delete: (id: string) =>
      deleteSamlIdentityProviderConfigApi.deleteSamlIdentityProviderConfig(id),
  };
};
