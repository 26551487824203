import type {
  RegisterInput,
  Configuration,
  CreateUserInput,
  UpdateUserInput,
} from '@repo/api-gw-sdk';
import {
  CreateUserApi,
  DeleteUserApi,
  ListUsersApi,
  RegisterApi,
  UpdateUserOverrideApi,
  UpdateViewerRoleApi,
  RefreshApi,
  LogoutApi,
  GetViewerApi,
} from '@repo/api-gw-sdk';
import type { ColumnSort, PaginationState } from '@tanstack/react-table';

import type { Condition } from '@/types/advanceFilter';

import type { HttpClient } from './httpClient';

const baseUrl = '/users';

export const users = (httpClient: HttpClient, config: Configuration) => {
  const logoutApi = new LogoutApi(config);
  const refreshApi = new RefreshApi(config);
  const registerApi = new RegisterApi(config);
  const listUsersApi = new ListUsersApi(config);
  const deleteUserApi = new DeleteUserApi(config);
  const createUserApi = new CreateUserApi(config);
  const updateUserApi = new UpdateUserOverrideApi(config);
  const updateViewerRoleApi = new UpdateViewerRoleApi(config);
  const getViewerApi = new GetViewerApi(config);

  return {
    get: (
      pagination: PaginationState,
      sorting: ColumnSort,
      columnFilters: Condition | undefined
    ) =>
      httpClient.execute([baseUrl, pagination, sorting, columnFilters], () =>
        listUsersApi.listUsers(
          pagination.pageIndex,
          pagination.pageSize,
          `${sorting.id}:${sorting.desc ? 'desc' : 'asc'}`,
          columnFilters ? JSON.stringify(columnFilters) : undefined
        )
      ),
    create: (user: CreateUserInput) => createUserApi.createUser(user),
    update: (id: string, user: UpdateUserInput) =>
      updateUserApi.updateUserOverride(id, user),
    delete: (id: string) => deleteUserApi.deleteUser(id),
    register: (registerInput: RegisterInput) =>
      registerApi.register(registerInput),
    changeRole: (roleId: string) =>
      updateViewerRoleApi.updateViewerRole({ roleId: roleId }),
    refreshToken: (redirectUrl: string) => refreshApi.refresh(redirectUrl),
    logout: () => logoutApi.logout(),
    getViewer: () =>
      httpClient.execute(['/viewer'], () => getViewerApi.getViewer()),
  };
};
