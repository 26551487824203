import {
  GetCustomerUIPreferencesApi,
  GetViewerUIPreferencesApi,
  UpdateCustomerUIPreferencesApi,
  UpdateViewerUIPreferencesApi,
  type Configuration,
} from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

export const preferences = (httpClient: HttpClient, config: Configuration) => {
  let version = 0;
  const getCustomerUIPreferencesApi = new GetCustomerUIPreferencesApi(config);
  const updateCustomerUIPreferencesApi = new UpdateCustomerUIPreferencesApi(
    config
  );
  const getViewerUIPreferencesApi = new GetViewerUIPreferencesApi(config);
  const updateViewerUIPreferencesApi = new UpdateViewerUIPreferencesApi(config);

  return {
    getUserPref: (key: PreferencesKey) =>
      httpClient.execute(['get-user-pref', key, version], () =>
        getViewerUIPreferencesApi
          .getViewerUIPreferences(key)
          .catch(() => undefined)
      ),
    getCustomerPref: (key: PreferencesKey) =>
      httpClient.execute(['get-customer-pref', key, version], () =>
        getCustomerUIPreferencesApi
          .getCustomerUIPreferences(key)
          .catch(() => undefined)
      ),
    updateCustomerPref: (key: PreferencesKey, value: object) => {
      version++;
      return updateCustomerUIPreferencesApi.updateCustomerUIPreferences(
        key,
        value
      );
    },
    updateUserPref: (key: PreferencesKey, value: object) => {
      version++;
      return updateViewerUIPreferencesApi.updateViewerUIPreferences(key, value);
    },
  };
};

export enum PreferencesKey {
  BackupVault = 'backup-vaults',
  CurrentProject = 'current-project',
}
