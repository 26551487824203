import type { CreateAccountInput, Configuration } from '@repo/api-gw-sdk';
import {
  CreateProjectSourceAccountApi,
  DeleteSourceAccountApi,
  ListProjectSourceAccountsApi,
  CreateVaultAccountApi,
  DeleteVaultAccountApi,
  ListVaultAccountsApi,
  CreateProjectRestoreAccountApi,
  DeleteRestoreAccountApi,
  ListProjectRestoreAccountsApi,
} from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

const baseUrl = '/accounts';

export const cloudAccounts = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const listSourceAccountsApi = new ListProjectSourceAccountsApi(config);
  const createSourceAccountApi = new CreateProjectSourceAccountApi(config);
  const deleteSourceAccountApi = new DeleteSourceAccountApi(config);
  const listVaultAccountsApi = new ListVaultAccountsApi(config);
  const createVaultAccountApi = new CreateVaultAccountApi(config);
  const deleteVaultAccountApi = new DeleteVaultAccountApi(config);
  const listRestoreAccountsApi = new ListProjectRestoreAccountsApi(config);
  const createRestoreAccountApi = new CreateProjectRestoreAccountApi(config);
  const deleteRestoreAccountApi = new DeleteRestoreAccountApi(config);

  return {
    source: {
      create: (account: CreateAccountInput) =>
        createSourceAccountApi.createProjectSourceAccount(
          currentProjectId,
          account
        ),
      delete: (id: string) => deleteSourceAccountApi.deleteSourceAccount(id),
      get: () =>
        httpClient.execute([baseUrl, '/source', currentProjectId], async () => {
          if (!currentProjectId) {
            return;
          }
          return listSourceAccountsApi.listProjectSourceAccounts(
            currentProjectId
          );
        }),
    },
    vault: {
      create: (account: CreateAccountInput) =>
        createVaultAccountApi.createVaultAccount(account),
      delete: (id: string) => deleteVaultAccountApi.deleteVaultAccount(id),
      get: () =>
        httpClient.execute([baseUrl, '/vault'], () =>
          listVaultAccountsApi.listVaultAccounts()
        ),
    },
    restore: {
      create: (account: CreateAccountInput) =>
        createRestoreAccountApi.createProjectRestoreAccount(
          currentProjectId,
          account
        ),
      delete: (id: string) => deleteRestoreAccountApi.deleteRestoreAccount(id),
      get: () =>
        httpClient.execute([baseUrl, '/restore'], () =>
          listRestoreAccountsApi.listProjectRestoreAccounts(currentProjectId)
        ),
    },
  };
};
