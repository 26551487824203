import type {
  RestoreVolumeInput,
  Configuration,
  CreateAccountInput,
  RestoreFilesInput,
} from '@repo/api-gw-sdk';
import {
  ListRestoreAccountsApi,
  ListAvailabilityZonesApi,
  ListEncryptionKeysApi,
  ListRestoreRegionsApi,
  RestoreVolumeApi,
  RestoreFilesApi,
  CreateProjectRestoreAccountApi,
} from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

import type { S3Bucket } from '../restore/types';
import type { Response } from '../types/responses';

const baseUrl = '/restore';

export const restore = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const restoreFilesApi = new RestoreFilesApi(config);
  const restoreVolumeApi = new RestoreVolumeApi(config);
  const listEncryptionKeysApi = new ListEncryptionKeysApi(config);
  const listRestoreRegionsApi = new ListRestoreRegionsApi(config);
  const listRestoreAccountsApi = new ListRestoreAccountsApi(config);
  const createProjectRestoreAccount = new CreateProjectRestoreAccountApi(
    config
  );
  const listAvailabilityZonesApi = new ListAvailabilityZonesApi(config);

  return {
    volume: (
      resourceId: string,
      volumeId: string,
      payload: RestoreVolumeInput
    ) =>
      restoreVolumeApi.restoreVolume(
        currentProjectId,
        resourceId,
        volumeId,
        payload
      ),
    files: (
      resourceId: string,
      snapshotId: string,
      restoreFilesInput: RestoreFilesInput
    ) =>
      restoreFilesApi.restoreFiles(
        currentProjectId,
        resourceId,
        snapshotId,
        restoreFilesInput
      ),
    accounts: {
      get: () =>
        httpClient.execute([baseUrl, '/accounts'], () =>
          listRestoreAccountsApi.listRestoreAccounts()
        ),
      create: (payload: CreateAccountInput) =>
        createProjectRestoreAccount.createProjectRestoreAccount(
          currentProjectId,
          {
            ...payload,
            regions: ['us-east-1'], // TODO: Remove this hardcoded value
          }
        ),
    },
    getEncryptionKeys: (cloudAccountId: string, regionName: string) =>
      httpClient.execute([baseUrl, cloudAccountId, '/encryptionKeys'], () =>
        listEncryptionKeysApi.listEncryptionKeys(
          cloudAccountId,
          regionName,
          currentProjectId
        )
      ),
    regions: {
      getForAccount: (cloudAccountId: string) =>
        httpClient.execute([baseUrl, cloudAccountId, '/regions'], () =>
          listRestoreRegionsApi.listRestoreRegions(
            cloudAccountId,
            currentProjectId
          )
        ),
    },
    availabilityZones: {
      get: (cloudAccountId: string, region: string) =>
        httpClient.execute(
          [baseUrl, cloudAccountId, '/regions', region, '/availabilityZones'],
          () =>
            listAvailabilityZonesApi.listAvailabilityZones(
              cloudAccountId,
              region,
              currentProjectId
            )
        ),
    },
    buckets: {
      get: (accountId: string) =>
        httpClient.get<Response<S3Bucket[]>>(`${baseUrl}/buckets/${accountId}`),
    },
  };
};
