import { createConfiguration, ServerConfiguration } from '@repo/api-gw-sdk';
export const createConfig = (
  baseUrl: string,
  authToken: string | undefined,
  middlewares: NonNullable<
    NonNullable<Parameters<typeof createConfiguration>[0]>['promiseMiddleware']
  > = []
) =>
  createConfiguration({
    baseServer: new ServerConfiguration(baseUrl, {}),
    ...(authToken
      ? {
          authMethods: {
            ApiKeyAuth: authToken,
          },
        }
      : {}),
    promiseMiddleware: middlewares,
  });
