import { RefreshApi, ResponseContext } from '@repo/api-gw-sdk';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useMemo, useState } from 'react';

import { createConfig } from '@/contexts/api-config';
import { useEnvironment } from '@/contexts/useEnvironment';
import { useUser } from '@/contexts/useUser';
import { getParsedJwt } from '@/libs/auth';

import { dalFactory } from './dalFactory';
import { createHttpClient } from './httpClient';

export function useDAL(projectId?: string) {
  const {
    clientApiBaseUrl: baseUrl,
    authToken,
    exposedTestingGlobals,
    updateAuthToken,
  } = useEnvironment();

  const { currentProjectId } = useUser(true);
  const proj = projectId ?? currentProjectId;

  const dal = useMemo(() => {
    const httpClient = createHttpClient(baseUrl, authToken);
    const configuration = createConfig(baseUrl, authToken, [
      {
        pre: async (context) => {
          if (!context.getUrl().endsWith('/refresh')) {
            const jwt = getParsedJwt(authToken);
            const exp = jwt?.exp;
            if (typeof exp === 'number') {
              const now = Math.floor(Date.now() / 1000);
              // if token expires in a minute, refresh it
              if (exp < now - 60) {
                await new RefreshApi(configuration).refresh();
                updateAuthToken();
              }
            }
          }

          return context;
        },
        post: async (context) => {
          const body = await context.body.text();
          if (body?.startsWith('response body')) {
            console.error('Response body is not a JSON:', body);
            throw new Error(body);
          }
          return new ResponseContext(context.httpStatusCode, context.headers, {
            ...context.body,
            text: () => Promise.resolve(body),
          });
        },
      },
    ]);

    if (exposedTestingGlobals && typeof window !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).populateTestData = async () => {
        await httpClient.put(`/projects/${proj}/populate?version=1`, '{}');
        await httpClient.put(
          `/projects/${proj}/inventory/populate?version=1`,
          '{}'
        );
        await httpClient.put(
          `/projects/${proj}/search/populate?version=1`,
          '{}'
        );
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).CleanPopulatedTestData = async () => {
        // await httpClient.put(
        //   `/projects/${currentProjectId}/populate?version=1`,
        //   '{}'
        // );
        // await httpClient.put(
        //   `/projects/${currentProjectId}/inventory/populate?version=1`,
        //   '{}'
        // );
        await httpClient.delete(
          `/projects/${currentProjectId}/search/populate?version=1`
        );
      };
    }

    return dalFactory(httpClient, configuration, proj!); // TODO: Take care of case when currentProjectId is null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, baseUrl, proj]);

  return dal;
}

const defaultValueExtractor = (
  value: string | undefined,
  defaultValue: unknown
) => {
  switch (typeof defaultValue) {
    case 'string': {
      return value ? value : defaultValue;
    }
    case 'number': {
      const parsed = parseInt(value || '', 10);
      return isNaN(parsed) ? defaultValue : parsed;
    }
    case 'boolean': {
      return !value ? defaultValue : value === 'true';
    }
  }

  throw new Error('Invalid default value type');
};

export function useShallowNavigation(
  defaultValues: Record<string, string | number | boolean>
) {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const [params, setParams] = useState(
    Object.entries(defaultValues).reduce(
      (agg, [key, value]) => {
        agg[key] = String(defaultValueExtractor(agg[key], value));
        return agg;
      },
      Object.fromEntries(searchParams?.entries() || [])
    )
  );

  useEffect(() => {
    const newUrl = Object.keys(params).length
      ? `${pathname}?${new URLSearchParams(params).toString()}`
      : pathname;

    if (newUrl !== window.location.pathname + window.location.search) {
      window.history.pushState(
        { ...window.history.state, as: newUrl, url: newUrl },
        '',
        newUrl
      );
    }
  }, [pathname, params]);

  return [params, setParams] as const;
}
