import type { Configuration } from '@repo/api-gw-sdk';
import { ListRolesApi } from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

const baseUrl = '/roles';

export const roles = (httpClient: HttpClient, config: Configuration) => {
  const listRolesApi = new ListRolesApi(config);

  return {
    get: () => httpClient.execute([baseUrl], () => listRolesApi.listRoles()),
  };
};
