import {
  type CreatePolicyInput,
  CreateBackupPolicyApi,
  DeleteBackupPolicyApi,
  ListBackupPoliciesApi,
  type BackupPolicy,
  type Configuration,
  UpdateBackupPolicyApi,
  GetBackupPolicyApi,
} from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

export const backupPolicy = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const getPolicyApi = new GetBackupPolicyApi(config);
  const listPoliciesApi = new ListBackupPoliciesApi(config);
  const createApi = new CreateBackupPolicyApi(config);
  const editApi = new UpdateBackupPolicyApi(config);
  const deleteApi = new DeleteBackupPolicyApi(config);
  return {
    get: (id: string) =>
      httpClient.execute(['getbackuppolicy', id], () =>
        getPolicyApi.getPolicy(id, currentProjectId)
      ),
    list: () =>
      httpClient.execute(['listbackuppolicies'], () =>
        listPoliciesApi.listPolicies(currentProjectId)
      ),
    create: (policy: CreatePolicyInput) =>
      createApi.createPolicy(currentProjectId, policy),
    update: (policy: BackupPolicy) =>
      editApi.updatePolicy(policy.id, currentProjectId, policy),
    delete: (policyId: string) =>
      deleteApi.deletePolicy(policyId, currentProjectId),
  };
};
