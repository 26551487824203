import useSWR, { type SWRConfiguration, type SWRHook } from 'swr';

import type { Response } from '../types/responses';

const fetchFactory =
  (authToken: string) => (url: string, options?: RequestInit) =>
    fetch(url, {
      ...options,
      headers: {
        Authorization: authToken,
        ...options?.headers,
      },
    }).then((res) => {
      if (res.status === 204) {
        // No content
        return;
      }
      return res.json();
    });

export type HttpClient = ReturnType<typeof createHttpClient>;

const swrDefaultConfig = {
  keepPreviousData: true,
  revalidateOnFocus: false,
};

export const createHttpClient = (baseUrl: string, authToken: string) => {
  const fetcher = fetchFactory(authToken);

  return {
    get: <T extends Response<unknown>>(
      url: string,
      swrOptions?: SWRConfiguration<T>
    ) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { data, ...rest } = useSWR<T>(baseUrl + url, fetcher, {
        ...swrDefaultConfig,
        ...swrOptions,
      });
      return {
        ...rest,
        body: data,
      };
    },
    fetch: <T extends Response<unknown>>(url: string) => {
      return fetcher(baseUrl + url, {
        method: 'GET',
      }) as Promise<T>;
    },
    post: <T extends Response<unknown>>(url: string, body: unknown) => {
      return fetcher(baseUrl + url, {
        method: 'POST',
        body: JSON.stringify(body),
      }) as Promise<T>;
    },
    put: <T extends Response<unknown>>(url: string, body: unknown) => {
      return fetcher(baseUrl + url, {
        method: 'PUT',
        body: JSON.stringify(body),
      }) as Promise<T>;
    },
    patch: <T extends Response<unknown>>(url: string, body: unknown) => {
      return fetcher(baseUrl + url, {
        method: 'PATCH',
        body: JSON.stringify(body),
      }) as Promise<T>;
    },
    delete: <T extends Response<unknown>>(url: string) => {
      return fetcher(baseUrl + url, {
        method: 'DELETE',
      }) as Promise<T>;
    },
    execute: <T>(
      key: Parameters<SWRHook>[0],
      executer: () => Promise<T>,
      swrOptions?: SWRConfiguration
    ) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { data, ...rest } = useSWR(key, executer, {
        ...swrDefaultConfig,
        ...swrOptions,
      });
      return {
        ...rest,
        body: data,
      };
    },
  };
};
