import type {
  UpdateControlViolationInput,
  Configuration,
} from '@repo/api-gw-sdk';
import {
  GetInventoryResourceApi,
  GetVolumeApi,
  ListInventoryApi,
  ListInventoryItemSnapshotsApi,
  ListInventoryAppsApi,
  ListInventorySourceRegionsApi,
  ListInventorySubnetsApi,
  ListInventoryNetworksApi,
  ListInventoryBackupRegionsApi,
  ListInventoryResourceTypesApi,
  ListInventoryEnvironmentsApi,
  ListControlViolationsApi,
  UpdateControlViolationApi,
} from '@repo/api-gw-sdk';
import type { ColumnSort, PaginationState } from '@tanstack/react-table';

import { toJsonText } from '@/components/queryBuilder/QueryLanguageConverter';
import type { Condition } from '@/types/advanceFilter';

import type { HttpClient } from './httpClient';

const baseUrl = '/inventory';

export const inventory = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const getVolumeApi = new GetVolumeApi(config);
  const listInventoryApi = new ListInventoryApi(config);
  const getInventoryResourceApi = new GetInventoryResourceApi(config);
  const listInventoryItemSnapshotsApi = new ListInventoryItemSnapshotsApi(
    config
  );
  const listInventoryAppsApi = new ListInventoryAppsApi(config);
  const listInventorySubnetsApi = new ListInventorySubnetsApi(config);
  const listInventoryNetworksApi = new ListInventoryNetworksApi(config);
  const listInventoryEnvironmentsApi = new ListInventoryEnvironmentsApi(config);
  const listInventoryBackupRegionsApi = new ListInventoryBackupRegionsApi(
    config
  );
  const listInventoryResourceTypesApi = new ListInventoryResourceTypesApi(
    config
  );
  const listInventorySourceRegionsApi = new ListInventorySourceRegionsApi(
    config
  );
  const listControlViolationsApi = new ListControlViolationsApi(config);
  const updateControlViolationApi = new UpdateControlViolationApi(config);

  return {
    list: (
      pagination: PaginationState,
      sorting: ColumnSort,
      columnFilters?: Condition | undefined
    ) =>
      listInventoryApi.listInventory(
        pagination.pageIndex,
        pagination.pageSize,
        `${sorting.id}:${sorting.desc ? 'desc' : 'asc'}`,
        currentProjectId,
        columnFilters ? toJsonText(columnFilters) : undefined
      ),
    get: (
      pagination: PaginationState,
      sorting: ColumnSort,
      columnFilters: Condition | undefined
    ) =>
      httpClient.execute([baseUrl, pagination, sorting, columnFilters], () =>
        listInventoryApi.listInventory(
          pagination.pageIndex,
          pagination.pageSize,
          `${sorting.id}:${sorting.desc ? 'desc' : 'asc'}`,
          currentProjectId,
          columnFilters ? toJsonText(columnFilters) : undefined
        )
      ),
    getOne: (id: string) =>
      httpClient.execute([baseUrl, id], () =>
        getInventoryResourceApi.getInventoryResource(id, currentProjectId)
      ),
    volumes: {
      get: (id: string, resourceId: string) =>
        httpClient.execute([baseUrl, '/volumes', id], () =>
          getVolumeApi.getVolume(resourceId, currentProjectId, id)
        ),
    },
    snapshots: {
      get: (
        id: string,
        type: 'eon' | 'gp',
        pageIndex: number,
        pageSize: number
      ) =>
        httpClient.execute([baseUrl, id, type], () =>
          listInventoryItemSnapshotsApi.listInventoryItemSnapshots(
            id,
            pageIndex,
            pageSize,
            type,
            currentProjectId
          )
        ),
    },
    apps: {
      get: () =>
        httpClient.execute([baseUrl, '/apps'], () =>
          listInventoryAppsApi.listInventoryApps(currentProjectId)
        ),
    },
    regions: {
      get: () =>
        httpClient.execute([baseUrl, '/source-regions'], () =>
          listInventorySourceRegionsApi.listInventorySourceRegions(
            currentProjectId
          )
        ),
    },
    subnets: {
      get: () =>
        httpClient.execute([baseUrl, '/subnets'], () =>
          listInventorySubnetsApi.listInventorySubnets(currentProjectId)
        ),
    },
    networks: {
      get: () =>
        httpClient.execute([baseUrl, '/networks'], () =>
          listInventoryNetworksApi.listInventoryNetworks(currentProjectId)
        ),
    },
    resourceTypes: {
      get: () =>
        httpClient.execute([baseUrl, '/resource-types'], () =>
          listInventoryResourceTypesApi.listInventoryResourceTypes(
            currentProjectId
          )
        ),
    },
    environments: {
      get: () =>
        httpClient.execute([baseUrl, '/environments'], () =>
          listInventoryEnvironmentsApi.listInventoryEnvironments(
            currentProjectId
          )
        ),
    },
    backupRegions: {
      get: () =>
        httpClient.execute([baseUrl, '/backup-regions'], () =>
          listInventoryBackupRegionsApi.listInventoryBackupRegions(
            currentProjectId
          )
        ),
    },
    violations: {
      list: (id: string) =>
        httpClient.execute([baseUrl, id, '/violations'], () =>
          listControlViolationsApi.listControlViolations(id, currentProjectId)
        ),
      update: (id: string, payload: UpdateControlViolationInput) =>
        updateControlViolationApi.updateControlViolation(
          id,
          currentProjectId,
          payload
        ),
    },
  };
};
