import {
  GetSourceStorageBackupStatusApi,
  GetBackedUpStorageDateHistogramApi,
  GetBackedUpResourcesApi,
  GetDashboardHomeDriftProtectionApi,
  GetBackupControlViolationsApi,
  type Configuration,
} from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

const baseUrl = '/dashboard/home/';

export const dashboards = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const getSourceStorageBackupStatusApi = new GetSourceStorageBackupStatusApi(
    config
  );
  const getBackedUpStorageDateHistogramApi =
    new GetBackedUpStorageDateHistogramApi(config);
  const getBackedUpResourcesApi = new GetBackedUpResourcesApi(config);
  const getDashboardHomeDriftProtectionApi =
    new GetDashboardHomeDriftProtectionApi(config);
  const getBackupControlViolationsApi = new GetBackupControlViolationsApi(
    config
  );

  return {
    getSourceStorageBackupStatus: () =>
      httpClient.execute([baseUrl, 'source-storage-backup-status'], () =>
        getSourceStorageBackupStatusApi.getSourceStorageBackupStatus(
          currentProjectId
        )
      ),
    getBackedUpStorageDateHistogram: () => {
      return httpClient.execute(
        [baseUrl, 'backed-up-storage-date-histogram'],
        () =>
          getBackedUpStorageDateHistogramApi.getBackedUpStorageDateHistogram(
            currentProjectId
          )
      );
    },
    getBackedUpByCloudProvider: (cloudProvider: string) => {
      return httpClient.execute(
        [baseUrl, 'backed-up-by-cloud-provider', cloudProvider],
        () =>
          getBackedUpResourcesApi.getBackedUpResources(
            cloudProvider,
            currentProjectId
          )
      );
    },
    getDriftProtection: () => {
      return httpClient.execute([baseUrl, 'drift-protection'], () =>
        getDashboardHomeDriftProtectionApi.getDashboardHomeDriftProtection(
          currentProjectId
        )
      );
    },
    getBackupControlViolations: () => {
      return httpClient.execute([baseUrl, 'backup-control-violations'], () =>
        getBackupControlViolationsApi.getBackupControlViolations(
          currentProjectId
        )
      );
    },
  };
};
