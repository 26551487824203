import type { Configuration, Control } from '@repo/api-gw-sdk';
import {
  ListControlsApi,
  CreateControlApi,
  UpdateControlApi,
  DeleteControlApi,
} from '@repo/api-gw-sdk';
import type { ColumnSort, PaginationState } from '@tanstack/react-table';

import type { HttpClient } from './httpClient';

const baseUrl = '/controls';

export const controls = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const listControlsApi = new ListControlsApi(config);
  const createControlApi = new CreateControlApi(config);
  const updateControlApi = new UpdateControlApi(config);
  const deleteControlApi = new DeleteControlApi(config);

  return {
    get: (pagination?: PaginationState, sorting?: ColumnSort) =>
      httpClient.execute([baseUrl, pagination, sorting], () =>
        listControlsApi.listControls(
          currentProjectId,
          pagination?.pageIndex,
          pagination?.pageSize,
          sorting && `${sorting.id}:${sorting.desc ? 'desc' : 'asc'}`
        )
      ),
    create: (control: Control) =>
      createControlApi.createControl(currentProjectId, control),
    update: (control: Control) =>
      updateControlApi.updateControl(control.id, currentProjectId, control),
    delete: (controlId: string) =>
      deleteControlApi.deleteControl(controlId, currentProjectId),
  };
};
