import {
  type Condition,
  ListJobsApi,
  type Configuration,
  CreateBackupJobApi,
} from '@repo/api-gw-sdk';
import type { ColumnSort } from '@tanstack/react-table';

import type { HttpClient } from './httpClient';

const baseUrl = '/jobs';

export const jobs = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const backupJobsApi = new ListJobsApi(config);
  const createBackupApi = new CreateBackupJobApi(config);
  const restoreJobsApi = new ListJobsApi(config);
  return {
    backup: {
      create: (resourceId: string, vaultId: string, retentionDays: number) => {
        return createBackupApi.createBackupJob(currentProjectId, {
          resourceId: resourceId,
          retentionDays: retentionDays,
          vaultId: vaultId,
        });
      },
      get: (
        pageIndex: number,
        pageSize: number,
        sorting: ColumnSort,
        columnFilters: Condition | undefined
      ) =>
        httpClient.execute(
          [baseUrl, '/', pageIndex, pageSize, sorting, columnFilters],
          () =>
            backupJobsApi.listJobs(
              currentProjectId,
              pageIndex,
              pageSize,
              'backup',
              `${sorting.id}:${sorting.desc ? 'desc' : 'asc'}`,
              columnFilters ? JSON.stringify(columnFilters) : undefined
            )
        ),
    },
    restore: {
      get: (
        pageIndex: number,
        pageSize: number,
        sorting: ColumnSort,
        columnFilters: Condition | undefined
      ) =>
        httpClient.execute(
          [baseUrl, '/', pageIndex, pageSize, sorting, columnFilters],
          () =>
            restoreJobsApi.listJobs(
              currentProjectId,
              pageIndex,
              pageSize,
              'restore',
              `${sorting.id}:${sorting.desc ? 'desc' : 'asc'}`,
              columnFilters ? JSON.stringify(columnFilters) : undefined
            )
        ),
    },
  };
};
