import {
  GetDatabasesByServerApi,
  GetSnapshotsByServerApi,
  type Configuration,
} from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

const baseUrl = '/db';

export const db = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const snapshotsApi = new GetSnapshotsByServerApi(config);
  const getDbApi = new GetDatabasesByServerApi(config);

  return {
    snapshots: (serverId: string, startDate: Date, endDate: Date) =>
      httpClient.execute([baseUrl, serverId, startDate, endDate], () =>
        snapshotsApi.getSnapshotsByServer(
          serverId,
          startDate,
          endDate,
          currentProjectId
        )
      ),
    objectTree: (serverId: string, snapshotId: string) =>
      httpClient.execute([baseUrl, serverId, snapshotId], () =>
        getDbApi.getDatabasesByServer(serverId, snapshotId, currentProjectId)
      ),
  };
};
