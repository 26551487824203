import { QueryDBApi, SearchApi, type Configuration } from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

const baseUrl = '/search';

export const search = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const searchApi = new SearchApi(config);
  const queryDbApi = new QueryDBApi(config);
  return {
    files: {
      get: (term: string, pageIndex: number, pageSize: number) =>
        httpClient.execute([baseUrl, '/files', term, pageIndex, pageSize], () =>
          searchApi.search(term, pageIndex, pageSize, currentProjectId)
        ),
    },
    dbs: {
      get: (term: string, pageIndex: number, pageSize: number) =>
        httpClient.execute([baseUrl, '/db', term, pageIndex, pageSize], () =>
          queryDbApi.queryDB(term, currentProjectId, pageIndex, pageSize)
        ),
    },
  };
};
