import {
  FileexplorerListdirectoriesApi,
  FileexplorerListfilesApi,
  type Configuration,
} from '@repo/api-gw-sdk';
import type { PaginationState } from '@tanstack/react-table';

import type { HttpClient } from './httpClient';

export const explorer = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const filesClient = new FileexplorerListfilesApi(config);
  const dirsClient = new FileexplorerListdirectoriesApi(config);
  return {
    getFiles: (
      resourceId: string,
      prefix: string,
      depth: number,
      pagination: PaginationState
    ) =>
      httpClient.execute(
        [
          'explorer_files',
          resourceId,
          prefix,
          depth,
          pagination.pageIndex,
          pagination.pageSize,
        ],
        () =>
          filesClient.fileexplorerListfiles(
            resourceId,
            prefix,
            depth,
            currentProjectId,
            pagination.pageIndex,
            pagination.pageSize
          )
      ),
    getDirectories: (
      resourceId: string,
      prefix: string,
      depth: number,
      pagination: PaginationState
    ) =>
      httpClient.execute(
        [
          'explorer_dirs',
          resourceId,
          prefix,
          depth,
          pagination.pageIndex,
          pagination.pageSize,
        ],
        () =>
          dirsClient.fileexplorerListdirectories(
            resourceId,
            prefix,
            depth,
            currentProjectId,
            pagination.pageIndex,
            pagination.pageSize
          )
      ),
  };
};
